import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { TabsPageComponent } from './tabs/tabs-page.component';
import { RedirectGuard } from './core/guards/redirect.guard';
import { environment } from '../environments/environment';
import { RedirectAppointmentsGuard } from './core/guards/redirect-appointments.guard';
import { RedirectDocumentsGuard } from './core/guards/redirect-documents.guard';

const routes: Routes = [
  {
    path: 'tabs',
    canActivate: [AuthGuard],
    component: TabsPageComponent,
    children: [
      {
        path: 'appointments',
        canActivate: [RedirectAppointmentsGuard],
        data: {
          target: environment.newWebUrl + '/appointments',
        },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/appointments/appointments.module').then((m) => m.AppointmentsPageModule),
          },
        ],
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
            data: {
              shortenChatPollingRate: true,
            },
          },
        ],
        data: {
          shortenChatPollingRate: true,
        },
      },
      {
        path: 'documents',
        canActivate: [RedirectDocumentsGuard],
        data: {
          target: environment.newWebUrl + '/documents',
        },
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/documents/documents.module').then((m) => m.DocumentsPageModule),
          },
        ],
      },
      {
        path: 'patients',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/patients/patients.module').then((m) => m.PatientsPageModule),
          },
        ],
      },
      {
        path: '',
        redirectTo: 'appointments',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    canActivate: [RedirectGuard],
    // We should never loadChildren and navigate unless there is an error in the RedirectGuard
    loadChildren: () => import('./pages/redirect/redirect.module').then((m) => m.RedirectPageModule),
    data: {
      target: environment.newWebUrl,
    },
  },
  {
    path: 'session',
    loadChildren: () => import('./session/session.module').then((m) => m.SessionModule),
  },
  {
    path: 'login',
    redirectTo: 'session/login',
  },
  {
    path: 'callback',
    redirectTo: 'session/callback',
  },
  {
    path: 'sign-up',
    redirectTo: 'session/sign-up',
  },
  {
    path: 'appointments',
    redirectTo: 'tabs/appointments',
  },
  {
    path: 'documents',
    redirectTo: 'tabs/documents',
  },
  {
    path: 'patients',
    redirectTo: 'tabs/patients',
  },
  {
    path: 'chat',
    redirectTo: 'tabs/chat',
  },
  {
    path: 'bill',
    redirectTo: 'billing',
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/billing/billing.module').then((m) => m.BillingPageModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/user/user.module').then((m) => m.UserPageModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
