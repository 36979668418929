import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  // Default interface options. Results in:
  // 1) Support of text wrapping for long labels
  // 2) Always showing Android styling to accomplish left-sided check per Thompson's request
  public interfaceOptions = {
    cssClass: 'alert-select-options-wrap-text',
    mode: 'md',
  };

  constructor(private platform: Platform) {}

  public checkIfPlatformSupported(): {
    isSupported: boolean;
    message?: string;
  } {
    return { isSupported: true };
  }
}
