import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../shared/shared.module';
import { AppointmentsPageComponent } from './appointments-page.component';
import { BookAppointmentDialogComponent } from './book-appointment-dialog/book-appointment-dialog.component';

const routes: Routes = [
  {
    path: '',
    component: AppointmentsPageComponent,
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModule],
  declarations: [AppointmentsPageComponent, BookAppointmentDialogComponent],
})
export class AppointmentsPageModule {}
